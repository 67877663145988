import React, {useEffect} from 'react';
import Authorization from "../authorization/authorization";
import Header from "../../components/header/header";
// import axios from "axios";
import Menu from "../../components/menu/menu";
import {Route, Switch} from "react-router-dom";
import {InstanceHead} from "../../API/InstanceHead";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfoAction} from "./actions";
import Organisation from "../organisation/organisation";
import Home from "../home/home";

const AppStack = () => {
    const isAuthorized = useSelector(state => state.auth.isAuthorized);
    const sessionId = useSelector(state => state.auth.sessionId);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            // const settingsUrl = `/companiesSettings/${window.location.hostname}/settings.json`;
            // // let settingsData = await axios.get(settingsUrl)
            InstanceHead.init('8AFFDEBA-986E-4A23-9885-2D9FEDA0A058')
            if (sessionId) {
                dispatch(getUserInfoAction(sessionId))
            }
        };
        fetchData();
    }, [dispatch, sessionId]);



    if (!isAuthorized) {
        return <Authorization/>
    }


    return (
        <div className="container">
            <div className="header">
                <Header/>
            </div>
            <div className="body">
                <Menu/>
                <div className="content">
                    <Switch>
                        <Route path="/" component={Home} exact/>
                        <Route path="/organization/:id" component={Organisation} exact/>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default AppStack;
