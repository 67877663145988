import {InstanceHead} from "./InstanceHead";

export const DocumentAPI = {
    async getActiveRegistrationRequests(sessionId) {
        return await InstanceHead.instance.get(`Organizations/GetActiveRegistrationRequests/${sessionId}`).then(res => {
            return res.data
        })
    },
   async refuseDocument(sessionId, registrationId, badDocuments, comment) {
        return await InstanceHead.instance.post(`Organizations/RefuseRegistration/${sessionId}`, {
            registrationId,
            documents: badDocuments,
            comment
        }).then(res => {
            return res.data;
        })
   },
    async ConfirmRegistration(sessionId, registrationId, organizationName) {
        return await InstanceHead.instance.post(`Organizations/ConfirmRegistration/${sessionId}`, {
            registrationId,
            organizationName
        }).then(res => {
            return res.data
        })
    }
};
