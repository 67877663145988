import React from "react";

export const Logo = () => (
    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.0404 0.850067L36.978 7.78773L0.46875 45.2727L6.39536 51.1982L43.1505 13.9602L50.8594 21.6691V0.850067H30.0404Z" fill="#43B826"/>
        <path d="M37.4944 28.9023C31.6103 28.9023 26.8241 33.6894 26.8241 39.5725C26.8241 45.4556 31.6103 50.2427 37.4944 50.2427C43.3775 50.2427 48.1646 45.4556 48.1646 39.5725C48.1646 33.6894 43.3776 28.9023 37.4944 28.9023ZM37.4944 44.3181C34.8777 44.3181 32.7488 42.1892 32.7488 39.5725C32.7488 36.9558 34.8777 34.8269 37.4944 34.8269C40.111 34.8269 42.2399 36.9558 42.2399 39.5725C42.24 42.1892 40.1111 44.3181 37.4944 44.3181Z" fill="#43B826"/>
        <path d="M12.8029 1.75958C6.91885 1.75958 2.13272 6.54669 2.13272 12.4298C2.13272 18.3129 6.91885 23.1 12.8029 23.1C18.686 23.1 23.4722 18.3129 23.4722 12.4298C23.4722 6.54669 18.686 1.75958 12.8029 1.75958ZM12.8029 17.1754C10.1863 17.1754 8.05736 15.0465 8.05736 12.4298C8.05736 9.81313 10.1863 7.68422 12.8029 7.68422C15.4196 7.68422 17.5475 9.81313 17.5475 12.4298C17.5475 15.0465 15.4196 17.1754 12.8029 17.1754Z" fill="#43B826"/>
    </svg>
);
export const ExitIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66671 10.8333V9.16666H14.1667V6.66666L18.3334 9.99999L14.1667 13.3333V10.8333H6.66671Z" fill="white"/>
            <path d="M3.33333 2.5H10.8333C11.7525 2.5 12.5 3.2475 12.5 4.16667V7.5H10.8333V4.16667H3.33333V15.8333H10.8333V12.5H12.5V15.8333C12.5 16.7525 11.7525 17.5 10.8333 17.5H3.33333C2.41417 17.5 1.66667 16.7525 1.66667 15.8333V4.16667C1.66667 3.2475 2.41417 2.5 3.33333 2.5Z" fill="white"/>
        </svg>
    )
}
