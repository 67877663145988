import React from 'react';

const Home = () => {
    return (
        <div className="home">
            <h3>Документы</h3>
            <span>Для начала работы выберите заявку из списка</span>
        </div>
    );
};

export default Home;
