import React, {useEffect, useState} from 'react';
import './organisation.scss';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {baseUrl} from "../../utils/baseURL";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useHistory} from "react-router-dom";
import {DocumentAPI} from "../../API/DocumentAPI";
import {getActiveRegstrationRequests} from "../app-stack/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageModal from "../../components/image-modal/image-modal";
import Confirm from "../../components/modals/confirm";
import Input from "../../components/modals/input";
import { Grid, Typography } from '@material-ui/core';

const Organisation = () => {
    const params = useParams();
    const sessionId = useSelector(state => state.auth.sessionId);
    const organizations = useSelector(state => state.auth.organizations);
    const apiKey = useSelector(state => state.auth.apiKey);
    const [badDocuments, setBadDocuments] = useState([]);
    const [document, setDocument] = useState(null);
    const [registrationId, setRegistrationId] = useState('');
    const [comment, setComment] = useState('');
    const [message, setMessage] = useState({
        refuse: "",
        success: ""
    });
    const [loading, setLoading] = useState(true);
    const [organizationName, setOrganizationName] = useState('');
    const [imageModal, setImageModal] = useState('');
    const [path, setPath] = useState('');
    const [success, setSuccess] = useState(false);
    const [refuse, setRefuse] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [inputModal, setInputModal] = useState(false);
    useEffect(() => {
        const data = organizations.find(item => item.registrationId === params.id);
        if (data) {
            setDocument({...data, documents: data.documents.map((el) => ({...el, comment: ""}))})
            setRegistrationId(data.registrationId)
            setOrganizationName(data.organizationName);
        }
    }, [organizations, params.id]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (sessionId) {
            dispatch(getActiveRegstrationRequests(sessionId))
            setLoading(false)
        }
    }, [dispatch, sessionId, success]);
    const history = useHistory();
    const changeRadioGroup = (item, pageItem) => (e) => {
        let name = e.target.name === "yes";
        if (name) {
            const docIndex = document.documents.findIndex(el => el.documentKey === item.documentKey)
            const pageIndex = document.documents[docIndex].pages.findIndex(el => el.documentFileId === pageItem.documentFileId)
            const newItem = {...document.documents[docIndex].pages[pageIndex], status: 'Confirmed'}
            const newPages = [...document.documents[docIndex].pages]
            newPages.splice(pageIndex,1, newItem)
            const newDoc = [...document.documents]
            const newDocData = {...document.documents[docIndex], pages: newPages}

            newDoc.splice(docIndex, 1 , newDocData)

            setDocument({
                ...document, documents: newDoc
            })
            // console.log(docIndex, 'i',newPages, newDoc, newDocData)
            // if (
            //     badDocuments.some(
            //         (c) => c.documentKey === item.documentKey && c.pages.length > 1
            //     )
            // ) {
            //     let removeBadDocments = badDocuments.map((x) => {
            //         return {
            //             ...x,
            //             pages: x.pages.filter(
            //                 (p) => p.documentFileId !== pageItem.documentFileId
            //             )
            //         };
            //     });
            //     setBadDocuments(removeBadDocments);
            // } else {
            //     let remove = badDocuments.filter(
            //         (el) => console.log(el.documentKey !== item.documentKey) 
            //     );
            //     setBadDocuments(remove);
            // }
        } else {
            const docIndex = document.documents.findIndex(el => el.documentKey === item.documentKey)
            const pageIndex = document.documents[docIndex].pages.findIndex(el => el.documentFileId === pageItem.documentFileId)
            const newItem = {...document.documents[docIndex].pages[pageIndex], status: 'Refused'}
            const newPages = [...document.documents[docIndex].pages]
            newPages.splice(pageIndex,1, newItem)
            const newDoc = [...document.documents]
            const newDocData = {...document.documents[docIndex], pages: newPages}

            newDoc.splice(docIndex, 1 , newDocData)

            setDocument({
                ...document, documents: newDoc
            })
           
            // if (badDocuments.some((el) => el.documentKey === item.documentKey)) {
            //     let data = badDocuments.map((elem) =>
            //         elem.documentKey === item.documentKey
            //             ? {...elem, pages: [...elem.pages, {...pageItem, status: 'Refused'}]}
            //             : elem
            //     );
            //     setBadDocuments(data);
            //     return;
            // } else {
            //     setBadDocuments([
            //         ...badDocuments,
            //         {...item, pages: [...item.pages, {...pageItem, status: 'Refused'}]}
            //     ]);
            // }
        }
    };

    const handleChangeValue = (text, index) => {
        if (badDocuments.length) {
            let data = badDocuments.map(item => {
                if (index === item.documentKey) {
                    item.comment = text
                }
                return item
            });
            setBadDocuments(data)
        } else {
            setDocument({
                ...document, documents: document.documents.map(item => {
                    if (index === item.documentKey) {
                        item.comment = text
                    }
                    return item
                })
            })
        }
    };
    const sendDoc = () => {
        let existComment = document.documents.some(el => el.comment);
        if (badDocuments.length || comment || existComment) {
            setRefuse(true)
        } else {
            setInputModal(true)
        }
    };

    const refuseClick = () => {
        DocumentAPI.refuseDocument(sessionId, registrationId, document.documents, comment).then(data => {
            if (data.success) {
                setSuccess(!success)
                setMessage({...message, refuse: "Вы успешно отклонили документ!!!"})
                history.push('/')
            }
        })
    };

    const confirmClick = () => {
        DocumentAPI.ConfirmRegistration(sessionId, registrationId, organizationName).then(data => {
            if (data.success) {
                setSuccess(!success)
                setOrganizationName('');
                setMessage({...message, refuse: "Вы успешно подтвердили документ!!!"})
                history.push('/')
            }
        })
    }
    const onCloseModalImage = () => {
        setImageModal(!imageModal)
    };

    const toggleConfirm = () => {
        setConfirm(!confirm);
    }
    const toggleRefuse = () => {
        setRefuse(!refuse)
    }
    const getPath = (path) => () => {
        onCloseModalImage()
        setPath(path)
    };
    const next = () => {
        setConfirm(true)
    }
    const handleChangeInput = e => {
        setOrganizationName(e.target.value)
    };
    const InputModalClose = () => {
        setOrganizationName('')
        setInputModal(false)
    }
    return (
        <>
            <div className="organizationInfo">
                <div className="organizationInfo__title">
                    Документы
                </div>
                <div className="organizationInfoBlock">
                    {loading ? <CircularProgress/> : document && document.documents.map((elem) => {
                        return <div className="organization__wrap">
                            <div className="small-title">
                                {elem.documentName}
                            </div>
                            {elem.pages && elem.pages.map((item, idx) => {
                                return <div className="organizationItem">
                                    <img
                                        onClick={getPath(item)}
                                        src={`${baseUrl()}api/Organizations/GetDocumentPhoto/${sessionId}/${params.id}/${item.documentFileId}/${apiKey}`}
                                        alt="123"/>
                                    <RadioGroup onChange={changeRadioGroup({...elem}, item)}>
                                        <FormControlLabel name="yes" value={'yes'}
                                                          control={<Radio color="primary"/>}
                                                          label="Да"/>
                                        <FormControlLabel name="no"
                                                          value={item.documentFileId}
                                                          control={<Radio/>} label="Нет"/>
                                    </RadioGroup>
                                </div>
                            })}
                            <div>
                                <input type="text" placeholder="Комментарий"
                                       onChange={(e) => handleChangeValue(e.target.value, elem.documentKey)}/>
                            </div>
                        </div>
                    })}
                   
                {document && document.store ?  <div className="organization__wrap"> <div>
                        <Grid container spacing={3} style={{marginTop: '10px'}}>
                            <Grid item xs={6}>
                            <Typography variant='h8'>
                            Название торговой точки
                        </Typography>
                            <Typography>
                            {document.store.storeName}
                        </Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Typography variant='h8'>
                            Область или город
                        </Typography>
                            <Typography>
                            {document.store.regionName}
                        </Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Typography variant='h8'>
                            Район
                        </Typography>
                            <Typography>
                            {document.store.districtName}
                        </Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Typography variant='h8'>
                            Адрес
                        </Typography>
                            <Typography>
                            {document.store.address}
                        </Typography>
                            </Grid>
                        </Grid>
                       
                        {/* <div style={{marginTop: '30px'}}>
                                <input type="text" placeholder="Комментарий"
                                       onChange={(e) => handleChangeValue(e.target.value, document.store.districtId)}/>
                            </div> */}
                    </div>  </div>: ''}
               
                </div>
               
                <div className="organization__footer">
                    <div className="small-title">
                        Общий комментарий к заявке
                    </div>
                    <TextField
                        label="Комментарий"
                        autoComplete="current-password"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />

                    <div className="button-group">
                        <Button variant="outlined" onClick={() => history.push('/')}>Отмена</Button>
                        <Button variant="contained" onClick={sendDoc}>Обработать</Button>
                    </div>
                </div>
            </div>
            <ImageModal
                open={imageModal}
                onClose={onCloseModalImage}
                path={path}
            />
            <Confirm
                handleClick={confirmClick}
                open={confirm}
                title="Заявка"
                content="Вы уверены что хотите подтвердить?"
                onClose={toggleConfirm}
            />
            <Confirm
                handleClick={refuseClick}
                title="Заявка"
                content="Вы уверены что хотите отклонить?"
                open={refuse}
                onClose={toggleRefuse}
            />
            <Input
                next={next}
                open={inputModal}
                value={organizationName}
                onChange={handleChangeInput}
                onClose={InputModalClose}
            />
        </>
    );
};

export default Organisation;
