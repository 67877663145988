import React from 'react';
import './header.scss';
import {ExitIcon} from "../icons/icons";
import Typography from "@material-ui/core/Typography";
import {useSelector, useDispatch} from "react-redux";
import {SessionAPI} from "../../API/SessionAPI";
import {logoutAction} from "../../pages/app-stack/actions";

const Header = () => {
    const userInfo = useSelector(state => state.auth.getUserInfo);
    const sessionId = useSelector(state => state.auth.sessionId);
    const dispatch = useDispatch();
    const logoutClick = () => {
        if (sessionId) {
            SessionAPI.closeSession(sessionId);
            dispatch(logoutAction());
        }
    }
    return (
        <div>
            <div className="header__wrap">
                <div className="header__name">
                    <Typography variant="h3" component="h3"
                                className="title">Кабинет предпринимателя</Typography>
                </div>
                <div className="header__exit-wrap">
                    <div className="line-wrap">
                        <span>{userInfo && userInfo.fullName}</span>
                        <div className="line"></div>
                    </div>
                    <div className="header__exit" onClick={logoutClick}>
                        <ExitIcon/>
                        <span>Выйти</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
