import {sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem} from "../../utils/storage";
import {ActionTypes} from "./actions";

let initialState = {
    sessionId: sessionStorageGetItem('sessionId'),
    apiKey: "8AFFDEBA-986E-4A23-9885-2D9FEDA0A058",
    getUserInfo: null,
    isAuthorized: false,
    loading: false,
    organizations: []
};


export const authReducer = (state = initialState, action) => {
    console.log(state.organizations)
    const {type} = action;
    switch (type) {
        case ActionTypes.EMPLOYEE_PIN_CONFIRM:
            sessionStorageSetItem('sessionId', action.sessionId);
            return {
                ...state,
                sessionId: action.sessionId,
                isAuthorized: action.isAuthorized
            };
        case ActionTypes.GET_USER_INFO:
            return {
                ...state,
                getUserInfo: action.getUserInfo,
                isAuthorized: action.isAuthorized
            };
        case ActionTypes.GET_ORGANIZATION:
            return {
                ...state,
                organizations: action.organizations.reverse()
            };
        case ActionTypes.SET_LOADER:
            return {
                ...state,
                loading: action.loading
            };
        case ActionTypes.LOGOUT:
            sessionStorageRemoveItem('sessionId');
            return {
                ...state,
                getUserInfo: null,
                isAuthorized: false,
            }
        default:
            return state;
    }
};
