import React from "react";
import {Provider} from "react-redux";
import store from "./store/store";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Route, Switch} from 'react-router-dom'
import AppStack from "./pages/app-stack/app-stack";
import './index.scss';


const theme = createMuiTheme({
    typography: {
        fontFamily: 'Open Sans, sans-serif',
    },
    palette: {
        primary: {main: '#50BF34', contrastText: '#fff'}
    }
});


function App() {
  return (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Switch>
                <Route path="/" component={AppStack}/>
            </Switch>
        </ThemeProvider>
    </Provider>
  );
}

export default App;
