import AuthAPI from "../../API/AuthAPI";
import {SessionAPI} from "../../API/SessionAPI";
import {DocumentAPI} from "../../API/DocumentAPI";

export const ActionTypes = {
    GET_ORGANIZATION: 'GET_ORGANIZATION',
    EMPLOYEE_PIN_CONFIRM: 'EMPLOYEE_PIN_CONFIRM',
    GET_USER_INFO: 'GET_USER_INFO',
    SET_LOADER: 'SET_LOADER',
    LOGOUT: 'LOGOUT',
};

const setLoaderAction = (loading) => ({
    type: ActionTypes.SET_LOADER,
    loading
});


const employeePinConfirmAction = (sessionId, isAuthorized) => ({
    type: ActionTypes.EMPLOYEE_PIN_CONFIRM,
    sessionId,
    isAuthorized
});

export const authAction = (organisation, employee, pin, app) => async dispatch => {
    dispatch(setLoaderAction(true))
    try {
        dispatch(setLoaderAction(false))
        const result = await AuthAPI.employeeCheck(organisation, employee, pin, app);
        dispatch(employeePinConfirmAction(result.data.sessionId, true))
        return
    } catch ({response}) {
        alert(response.data && response.data.message)
    }
};


export const logoutAction = () => ({
    type: ActionTypes.LOGOUT
})


const getUserInfoActions = (getUserInfo, isAuthorized) => ({
    type: ActionTypes.GET_USER_INFO,
    getUserInfo,
    isAuthorized
})

export const getUserInfoAction = (sessionId) => async dispatch => {

    try {
        const result = await SessionAPI.getUserInfo(sessionId)
        dispatch(getUserInfoActions(result.data, true))
    } catch (e) {
    }
};


const getOrganizations = (organizations) => ({
    type: ActionTypes.GET_ORGANIZATION,
    organizations
})
export const getActiveRegstrationRequests = (sessionId) => async dispatch => {
    dispatch(setLoaderAction(true))
    try {
        const result = await DocumentAPI.getActiveRegistrationRequests(sessionId)
        dispatch(setLoaderAction(false))
        dispatch(getOrganizations(result))
    } catch (e) {
        console.log(e)
    }
}

