import React, {useEffect, useState} from 'react';
import './authorization.scss';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Logo} from "../../components/icons/icons";
import AuthAPI from "../../API/AuthAPI";
import {useDispatch} from "react-redux";
import {authAction} from "../app-stack/actions";
import SnackbarAlert from "../../components/Snackbar/SnackbarAlert";

const Authorization = () => {
    const [form, setForm] = useState({
        innOrganization: '',
        innEmployee: '',
        password: ''
    });
    const [organizationName, setOrganizationName] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [notification, setNotification] = useState(false);
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    const catchError = (message) => {
        setMessage(message);
        setNotification(true);
    };


    const handleChange = ({target: {value, name}}) => {
        setForm({...form, [name]: value})
    };
    useEffect(() => {
        if (form.innOrganization.length === 14) {
            AuthAPI.getNameByInn(form.innOrganization).then(res => {
                setOrganizationName(res.data.name)
            }).catch(({response}) => catchError(response.data.message))
        } else {
            setOrganizationName('')
        }
    }, [form.innOrganization])
    useEffect(() => {
        if (form.innEmployee.length === 14) {
            AuthAPI.getNameByInn(form.innEmployee).then(res => {
                setEmployeeName(res.data.name)
            }).catch(({response}) => catchError(response.data.message))
        } else {
            setEmployeeName('')
        }
    }, [form.innEmployee]);

    const handleClick = (e) => {
        e.preventDefault();
        if (form.innEmployee.length === 14 && form.innOrganization.length === 14) {
                AuthAPI.employeeCheckInn(form.innOrganization, form.innEmployee).then(res => {
                    if(res.success) {
                        dispatch(authAction(form.innOrganization, form.innEmployee, form.password, 'Salyk'))
                    }
                }).catch(({response}) => catchError(response.data.message))
            }else {
            alert("Инн не менее 14 символов")
        }
    };

    return (
        <div className="authorization">
            <div className="authorization__block">
                <div className="authorization__logo">
                    <Logo/>
                </div>
                <form className="authorization__form">
                    <h2>Кабинет предпринимателя</h2>
                    <div className="authorization__textField">
                        <TextField
                            name="innOrganization"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 14,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            helperText={organizationName}
                            label="ИНН организации "
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <span className="count"> {form.innOrganization.length} / 14</span>
                                    </InputAdornment>
                                ),
                            }}/>
                    </div>
                    <div className="authorization__textField">
                        <TextField
                            name="innEmployee"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 14,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            label="ИНН сотрудника "
                            helperText={employeeName}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <span className="count">  {form.innEmployee.length} / 14</span>
                                    </InputAdornment>
                                ),
                            }}/>
                    </div>
                    <div className="authorization__textField">
                        <TextField
                            name="password"
                            onChange={handleChange}
                            type="password"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            label="Пинкод"
                        />
                    </div>
                    <button className="btn" onClick={handleClick}>Войти</button>
                </form>
            </div>
            <SnackbarAlert
                notification={notification}
                setNotification={setNotification}
                message={message}
                severity="error"
                vertical="top"
                horizontal="center"/>
        </div>
    );
};

export default Authorization;
