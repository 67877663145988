import React, {useEffect} from 'react';
import './menu.scss';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {getActiveRegstrationRequests} from "../../pages/app-stack/actions";

const Menu = () => {
    const sessionId = useSelector(state => state.auth.sessionId);
    const organizations = useSelector(state => state.auth.organizations);
    const dispatch = useDispatch();
    useEffect(() => {
        if (sessionId) {
            dispatch(getActiveRegstrationRequests(sessionId))
        }
    }, [dispatch, sessionId]);
    const items = () => (
        <List>
            {organizations.map((item, idx) => (
                <NavLink activeClassName={"active"} to={`/organization/${item.registrationId}`}>
                    <ListItem button key={item.registrationId}>
                        <ListItemText primary={item.organizationInn}
                        />
                    </ListItem>
                </NavLink>
            ))}
        </List>
    );
    return (
        <Drawer variant="permanent" className="menuDrawer">
            <h2>Входящие заявки</h2>
            {items()}
        </Drawer>
    );
};

export default Menu;
