import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarAlert = ({notification, setNotification, message, severity, vertical, horizontal}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification(false);
    };
    return (
        <Snackbar
            open={notification}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: vertical, horizontal: horizontal}}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarAlert;


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
