import {combineReducers} from "redux";
import {authReducer} from "../pages/app-stack/reducers";




const rootReducer = combineReducers({
    auth: authReducer
});

export default rootReducer
