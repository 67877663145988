import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const Confirm = ({title, open, onClose, content, handleClick}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <div className="button-group">
                    <Button variant="outlined" onClick={onClose}>Отмена</Button>
                    <Button variant="contained" onClick={handleClick}>Сохранить</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default Confirm;
