import axios, { AxiosInstance } from 'axios';
import {baseUrl} from "../utils/baseURL";


export const InstanceHead =  {
    instance: AxiosInstance,
    init: function(apiKey) {
        this.instance = axios.create({
            baseURL: `${baseUrl()}api/`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "apiKey" : apiKey
            }
        })
    }
}
