export const sessionStorageSetItem = (key, value) => {
    try {
        sessionStorage.setItem(key, value);
    } catch (ex) {
        console.warn(ex.message);
    }
}

export const sessionStorageRemoveItem = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (ex) {
        console.warn(ex.message);
    }
}

export const sessionStorageGetItem = (key) => {
    try {
        return sessionStorage.getItem(key);
    }
    catch (ex) {
        console.warn(ex.message);
    }
    return null;
}
