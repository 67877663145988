import {InstanceHead} from "../API/InstanceHead";

const AuthAPI = {
    async getNameByInn(inn) {
        return await InstanceHead.instance.post('Auth/GetNameByInn', {inn})
    },
    async employeeCheck(innOrganisation, innEmployee, pin, app) {
        return await InstanceHead.instance.post('Auth/EmployeePinConfirm', {
            innOrganisation,
            innEmployee,
            pin,
            app
        })
    },
    async employeeCheckInn(innOrganisation, innEmployee) {
        return await InstanceHead.instance.post('Auth/EmployeeCheck', {
            innOrganisation,
            innEmployee,
        }).then(res => {
            return res.data
        })
    }
}

export default AuthAPI;

