import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import {baseUrl} from "../../utils/baseURL";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Magnifier from "react-magnifier";

import './image-modal.scss';
const ImageModal = ({path, open, onClose}) => {
    const sessionId = useSelector(state => state.auth.sessionId);
    const apiKey = useSelector(state => state.auth.apiKey);
    const {id} = useParams();
    return (
        <Dialog open={open} className="imageModal">
            <DialogTitle>
                <h3>{path.documentPageName}</h3>
                <span onClick={onClose}><Close /></span>
            </DialogTitle>
            <DialogContent>
                <Magnifier src={`${baseUrl()}api/Organizations/GetDocumentPhoto/${sessionId}/${id}/${path.documentFileId}/${apiKey}`} />
            </DialogContent>
        </Dialog>
    );
};

export default ImageModal;
