import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const Input = ({onChange, value, open, onClose, next}) => {
    return (
        <Dialog open={open}>
        <DialogContent className="Input">
            <DialogTitle>Наименование организации</DialogTitle>
            <DialogContent>
                <TextField
                    placeholder="Test Onoi"
                    value={value}
                    onChange={onChange}
                />
            </DialogContent>
            <DialogActions>
                <div className="button-group">
                    <Button variant="outlined" onClick={onClose}>Отмена</Button>
                    <Button variant="contained" onClick={next}>Продолжить</Button>
                </div>
            </DialogActions>
        </DialogContent>
        </Dialog>
    );
};

export default Input;
