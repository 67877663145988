import {InstanceHead} from "./InstanceHead";

export const SessionAPI = {
    async getUserInfo(sessionId) {
       return await InstanceHead.instance.get(`Session/GetUserInfo/${sessionId}`)
    },
    async closeSession(sessionId) {
        return await InstanceHead.instance.post(`Session/CloseSession/${sessionId}`).then(res => {
            return res.data;
        })
    }
};
